<template lang="pug">
  .user-manage
    .top-page.row.no-gutters
      .col-6.top-left.text-left.pl-3.pl-lg-5(style='z-index: 999;')
        img.top-logo(src='/img/description/logo.png' alt='')
        .top-slogan
          | スマホ１つで
          br
          |         岩場の情報がわかる
        .top-sub-slogane 外岩クライマー専用のWebサービス
      .col-6.position-relative
        img.top-img-1.float-right(src='/img/description/top_img_1.png' alt='')
        img.top-img-2.position-absolute(src='/img/description/top_img_2.png' alt='')
        img.top-img-3.position-absolute(src='/img/description/top_img_2.png' alt='')
    .paging
      .text-center.d-xl-none.mb-3
        a.btn-flat-border2(href='https://www.yamalab.jp/' target='_blank' rel='noopener') ヤマラボを始める
      .d-flex.flex-row
        //- スマホ表示
        .d-xl-none.text-center.row.no-gutters.justify-content-center.text-md-left.mt-2.w-100
          b-button.col-3.mr-md-4.btn-flat-border(@click="scrollCard('new-info')" variant="outline-info") 新着情報
          b-button.col-4.mr-md-4.btn-flat-border.text-nowrap(@click="scrollCard('overview')" variant="outline-info") ヤマラボとは
          b-button.col-2.mr-md-4.btn-flat-border(@click="scrollCard('function')" variant="outline-info") 機能
          b-button.col-4.btn-flat-border(@click="scrollCard('plan')" variant="outline-info") 料金プラン
          b-button.col-3.btn-flat-border(@click="scrollCard('faq')" variant="outline-info") FAQ
        //- ＰＣ表示
        .d-none.d-xl-block.text-center.justify-content-center.px-md-4.text-md-left.mt-2
          b-button.mr-md-4.btn-flat-border-pc.text-nowrap(@click="scrollCard('new-info')" variant="outline-info") 新着情報
          b-button.mr-md-4.btn-flat-border-pc.text-nowrap(@click="scrollCard('overview')" variant="outline-info") ヤマラボとは
          b-button.mr-md-4.btn-flat-border-pc.text-nowrap(@click="scrollCard('function')" variant="outline-info") 機能
          b-button.mr-md-4.btn-flat-border-pc.text-nowrap(@click="scrollCard('plan')" variant="outline-info") 料金プラン
          b-button.mr-md-4.btn-flat-border-pc.text-nowrap(@click="scrollCard('faq')" variant="outline-info") FAQ
        div
          a.col-6.col-md.text-right.d-none.d-xl-inline.btn-flat-border2.text-nowrap(href='https://www.yamalab.jp/' target='_blank' rel='noopener') ヤマラボを始める
    .lp-info.mt-md-5.mt-4
      .container.p-md-5.pb-5.pt-3.px-3
        #new-info
        .title2 新着情報
        .row.text-center.mt-3
          .col-6.col-md-3.mb-3
            a.text-white(href='/blog/20210330')
              img.blog-thumbnail-img(src='/img/blog/20210329/IMG_3197.jpg' alt='')
              .text-left.py-1.px-2.blog-info-title 豊田
              .text-left.px-2 2021/03/30
              .text-left.px-2.title-text お久しぶりです。ヤマラボ管理人です。
          .col-6.col-md-3.mb-3
            a.text-white(href='/blog/20210115')
              img.blog-thumbnail-img(src='/img/blog/20210115/DSCN2793.JPG' alt='')
              .text-left.py-1.px-2.blog-info-title 笠置山｜新しいトポ追加のお知らせ
              .text-left.px-2 2021/01/15
              .text-left.px-2.title-text 新年あけましておめでとうございます。ヤマラボ管理人です。
          .col-6.col-md-3.mb-3
            a.text-white(href='/blog/20201203')
              img.blog-thumbnail-img(src='/img/blog/20201203/DSC_0639.JPG' alt='')
              .text-left.py-1.px-2.blog-info-title フクベ・新川・大倉・白峰｜新しいトポ追加のお知らせ
              .text-left.px-2 2020/12/03
              .text-left.px-2.title-text こんにちは、ヤマラボ管理人です。
          .col-6.col-md-3.mb-3
            a.text-white(href='/blog/20201120')
              img.blog-thumbnail-img(src='/img/blog/20201120/IMG_2436-300x225.jpg' alt='')
              .text-left.py-1.px-2.blog-info-title フクベ(瓢) ＆ 笠置山
              .text-left.px-2 2020/11/20
              .text-left.px-2.title-text こんにちは。ヤマラボ管理人です。
        a.float-right.pb-4(href='/blog')
          .d-inline.text-white もっとみる
    div(style='background-color: white;')
      .container.p-md-3.p-2
        //- ヤマラボとは？
        #overview
        .title.m-2.pt-3 ヤマラボとは?
        .d-flex.flex-column.flex-lg-row
          .text-center.overview.p-md-4
            | ヤマラボは外岩クライマー専用のWebサービスです。岩のトポが写真で確認できたり、登ったトポを記録したりできます。
          .text-center.mt-3.position-relative.d-block.pl-md-5.pb-5
            img.phone-img(src='/img/description/top_img_3.png' alt='')
            img.position-absolute.cloud1(src='/img/description/top_img_2.png' alt='')
            img.position-absolute.cloud2(src='/img/description/top_img_2.png' alt='')
    .lp-info.mt-md-5.mt-4
      .container.pb-5
        //- 岩登りについて
        .title.m-2.pt-3.text-white 岩登りについて
        .text-1.px-3.p-md-4.text-white
          | 山や川沿いへ行くと大きな岩や岩壁があります。その岩を、自分の手足で登る遊びが岩登りです。登り方によって様々な種類があります。
        .d-flex.flex-column.flex-md-row.justify-content-center
          .text-center
            div
              .text-2 ボルダリング
            img.climbing-img.mt-3.px-3(src='/img/description/bouldering_img.jpg' alt='')
            .text-3 ボルダリングで登られる岩の形
            .text-4 4m程度までの大きな岩を登ります。
          .text-center
            div
              .text-2 ルートクライミング
            img.climbing-img.mt-3.px-3(src='/img/description/climbing_img.JPG' alt='')
            .text-3 ルートクライミングで登られる岩の形
            .text-4
              | 4m程度以上の岩壁をロープ使って
              br
              |             (安全確保のため)登ります。
    div(style='background-color: white;')
      .container.p-md-3.p-2
        //- ヤマラボの機能紹介
        #function
        .title.m-2.pt-3 ヤマラボの機能紹介
        //- 国内の約200岩場を掲載
        .d-flex.flex-column-reverse.flex-lg-row.justify-content-center.mt-3
          .text-center.mt-md-3.mx-md-3
            img.tool-img(src='/img/description/tool_img_1-1.jpg' alt='')
          .text-center.mt-md-3.mx-lg-2.box2
            div
              img.tool-img-item.mt-5.mt-lg-5(src='/img/description/tool_img_1-2.png' alt='')
              .text-5.mt-3.mt-lg-4.p-2 国内の約200岩場を掲載!
              .text-6.p-2
                | 全国の岩場のルートを掲載
                br
                |               キーワード検索から岩場検索
                br
                |               できます。
        //- 関東エリアの画像トポ公開
        .d-flex.flex-column.flex-lg-row.justify-content-center.mt-5
          .text-center.mt-md-3.mx-lg-2.box2
            div
              img.tool-img-item.mt-4.mt-lg-5(src='/img/description/tool_img_2-2.png' alt='')
              .text-5.mt-3.mt-lg-4.p-2 関東エリアの画像トポ公開!
              .text-6.p-2
                | ルートがわからない時写真でトポの確認
                br
                |               ができます。
                br
                |               ※掲載エリアは順次拡大予定
          .text-center.mt-md-3.mx-md-3
            img.tool-img(src='/img/description/tool_img_2-1.png' alt='')
        //- 近くのコンビニ・温泉表示
        .d-flex.flex-column-reverse.flex-lg-row.justify-content-center.mt-5
          .text-center.mt-md-3.mx-md-3
            img.tool-img(src='/img/description/tool_img_3-1.png' alt='')
          .text-center.mt-md-3.mx-lg-2.box2
            div
              img.tool-img-item.mt-4.mt-lg-5(src='/img/description/tool_img_3-2.png' alt='')
              .text-5.mt-3.mt-lg-4.p-2 近くのコンビニ・温泉表示
              .text-6.p-2
                | 外岩クライミングに便利な情報
                br
                |               そこまでの距離も表示することが
                br
                |               できます。
        //- クライミング活動の記録
        .d-flex.flex-column.flex-lg-row.justify-content-center.mt-5
          .text-center.mt-md-3.mx-lg-2.box2
            div
              img.tool-img-item.mt-4.mt-lg-5(src='/img/description/tool_img_4-2.png' alt='')
              .text-5.mt-3.mt-lg-4.p-2 クライミング活動の記録
              .text-6.p-2
                | ルート単位の登攀記録作成が可能
                br
                |               いつでも作成した記録を見返すこと
                br
                |               もできます。
          .text-center.mt-md-3.mx-md-3
            img.tool-img(src='/img/description/tool_img_4-1.jpg' alt='')
        //- 岩場のチェックイン数の表示
        .d-flex.flex-column-reverse.flex-lg-row.justify-content-center.mt-5
          .text-center.mt-md-3.mx-md-3
            img.tool-img(src='/img/description/tool_img_5-1.png' alt='')
          .text-center.mt-md-3.mx-lg-2.box2
            div
              img.tool-img-item.mt-4.mt-lg-5(src='/img/description/tool_img_5-2.png' alt='')
              .text-5.mt-3.mt-lg-4.p-2 岩場のチェックイン数の表示
              .text-6.pt-lg-4.p-2
                | 他ユーザーのチェックイン状況が
                br
                |               確認できる！
        //- クライミング活動の記録
        .d-flex.flex-column.flex-lg-row.justify-content-center.my-5
          .text-center.mt-md-3.mx-lg-2.box2
            div
              img.tool-img-item.mt-4.mt-lg-5(src='/img/description/tool_img_6-2.png' alt='')
              .text-5.mt-3.mt-lg-4.p-2 キャラクターバッジの取得
              .text-6.p-2
                | 記録やチェックインが増えると
                br
                |               キャラが成長。
                br
                |               ゲーム感覚で楽しむことができます。
          .text-center.mt-md-3.mx-md-3
            img.tool-img(src='/img/description/tool_img_6-1.jpg' alt='')
    .lp-info.mt-md-5.mt-4
      .container.p-md-5.pb-5.pt-3.px-3
        #plan
        .title.m-2.pt-3.text-white ヤマラボの料金プラン
        .text-1.px-3.px-md-4.text-white
          | 有料プランですべての機能が使えるようになります。
        .mt-4.text-center.text-white
          .pay-info.d-inline-block.p-4.px-5
            .text1 有料プラン
            .text2
              | 月額 
              span.yen 300
              |  円(税込み)
            .text-left.d-inline-block
              .text3 ・岩場の検索
              .text3 ・画像トポの参照
              .text3 ・登攀記録の作成
              .text3 ・岩場のチェックイン
          .title.mt-4.mt-md-5.text-white 初月は利用無料!!
          a(href='https://www.yamalab.jp/register_user/send_auth' target='_blank' rel='noopener')
            .add-button.d-inline-block.m-4.p-2.px-5 新規会員登録はこちら
    div(style='background-color: white;')
      .container.p-3
        #faq
        .title2(style='color: #2B546A;') FAQ
        .faq-title.py-2 Q. ヤマラボはアプリですか？
        .faq-text
          | A. ヤマラボはスマートフォンアプリではございません。
          br
          |         PC、タブレット、スマートフォン端末のWEBブラウザからご利用いただけます。
        hr(style='border:0;border-top:1px solid #707070;')
        .faq-title.py-2 Q. 無料で使えますか？
        .faq-text
          | A. ユーザー登録なしで全国の約200岩場を検索できます。有料プランにご契約頂きますと「岩場のルート一覧」や「画像トポ」の参照、「記録の作成」が可能になります。詳しくはこちらもご覧ください。
        .text-center.my-3
          a(href='https://www.yamalab.jp/info/faq' target='_blank' rel='noopener')
            .faq-button.d-inline-block.m-4.p-2.px-5 その他よくあるご質問はこちら
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    scrollCard(target){
      window.scrollTo({
        top: document.getElementById(target).getBoundingClientRect().top + window.pageYOffset - 60,
        //動きが見える移動
        behavior: "smooth"
      });
    }
  },
  components: {
  },
}
</script>

<style lang="stylus">
a
  color: #2B546A

  &:hover
    text-decoration: none
    color: #2B546A

// top画面

.blog-thumbnail-img
  width 100%
  border-radius 10px 
.blog-info-title
  white-space nowrap
  text-overflow ellipsis
  overflow hidden
  font-weight bold
.top-left
  padding-top: 3rem

.top-logo
  width: 330px

.top-slogan, .top-sub-slogane
  font-size: 1.5rem
  color: #2B546A
  font-weight: bold
  white-space: nowrap

.top-img-2
  width: 17%
  top: 7rem
  right: 28rem
  animation: img_box_9955 6s linear infinite
  transform-origin: 50% 50%
  margin: 1rem 0 !important

.top-img-3
  width: 25%

  // left: 7rem;
  top: 9rem
  right: 20rem
  animation: img_box_9955 8s linear infinite
  transform-origin: 50% 50%
  margin: 1rem 0 !important

@keyframes img_box_9955
  0%
    transform: translateY(0)

  33.33333%
    transform: translateY(-8px)

  66.66667%
    transform: translateY(8px)

  100%
    transform: translateY(0)

// ページングボタン

.paging
  margin-top: 1rem

.paging-text
  font-size: medium

.paging-text-pc
  font-size: large

.btn-flat-border
  font-size: 11px
  margin: 3px
  padding: 0.3em .5em !important
  text-decoration: none
  color: #ffffff
  background-color: #2B546A
  border: solid 2px #2B546A
  border-radius: 10px
  transition: .4s
  text-align: center

  &:hover
    background: #ffffff
    border: solid 2px #2B546A
    color: #2B546A

.btn-flat-border-pc
  font-size: medium
  margin: 10px !important
  padding: 0.3em 1em
  text-decoration: none
  color: #ffffff
  background-color: #2B546A
  border: solid 2px #2B546A
  border-radius: 10px
  transition: .4s
  text-align: center

  &:hover
    background: #ffffff
    border: solid 2px #2B546A
    color: #2B546A

.btn-flat-border2
  font-size: xx-large
  bottom: 10px
  margin: 3px !important
  padding: 0.3em 1em
  text-decoration: none
  color: #2B546A
  background-color: #ffffff
  border: solid 4px #2B546A
  border-radius: 15px
  transition: .4s

  &:hover
    background: #2B546A
    border: solid 4px #2B546A
    color: #ffffff
    opacity: 1

// ヤマラボとは

.lp-info
  background-color: #46ADE5

.title
  color: #2B546A
  font-weight: bold
  font-size: 40px

.title2
  color: #ffffff
  font-weight: bold
  font-size: 40px

.text-1, .overview
  color: #2B546A
  font-weight: bold
  font-size: x-large
  letter-spacing: 6px
  line-height: 2x

.phone-img
  z-index: 2
  max-width: 60%

.box, .box2
  margin: 0 6em
  font-weight: bold
  color: #2B546A

  //文字色
  border: solid 5px #2B546A

  //線
  border-radius: 20px

  //角の丸み

.blog-title
  font-weight: bold

.pay-info
  background-color: #FFCB3D
  border-radius: 30px
  box-shadow: 15px 15px 0px 0 rgb(184, 148, 50)

  .text1
    color: #2B546A
    font-size: 2.5rem

  .text2
    color: #2B546A
    font-size: 2rem

  .text3
    color: #2B546A
    font-size: 1rem

.yen
  font-size: 4rem

.add-button
  background-color: #ffffff
  border-radius: 50px
  font-size: xx-large

.faq-title
  color: #2B546A
  font-size: 1.7rem
  font-weight: bold

.faq-text
  color: #2B546A
  font-size: 1.2rem

.faq-button
  background-color: #FFCB3D
  border-radius: 50px
  font-weight: bold
  font-size: x-large

// ページ内遷移

// 
//   display: block
//   padding-top: 70px
//   margin-top: -70px

.transition
  color: #2B546A

.blog-title, .title-text
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

@media (min-width: 992px)
  .overview
    width: 50%
    display: flex
    justify-content: center
    align-items: center

// PC表示
@media (min-width: 768px)
  .text-1
    display: flex
    justify-content: center
    align-items: center

  .cloud1
    z-index: 3
    transform: scale(-1, 1)
    bottom: -30px
    left: 1px
    width: 35%
    animation: img_box_9955 6s linear infinite
    transform-origin: 50% 50%
    margin: 1rem 0 !important

  .cloud2
    z-index: 1
    transform: scale(-1, 1)
    bottom: 60px
    width: 20%
    animation: img_box_9955 8s linear infinite
    transform-origin: 50% 50%
    margin: 1rem 0 !important

  .climbing-img
    max-width: 100%
    box-sizing: border-box

  .text-2
    border-bottom: solid 3px #FFDF88
    color: #ffffff
    font-size: large
    font-weight: bold
    display: inline-block

  .text-3
    color: #ffffff
    font-size: medium
    padding: 5px
    font-weight: bold

  .text-4
    color: #ffffff
    font-size: medium
    font-weight: bold
    padding: 10px
    display: inline-block

  .tool-img
    max-width: 600px

  .tool-img-item
    max-width: 80px

  .box, .box2
    max-width: 600px

  .text-5
    font-size: 20px
    padding: 1em 0

  .text-6
    font-size: 13px

// スマホ表示
@media only screen and (max-width: 767px)
  .top-left
    padding-top: 1rem

  .top-logo
    width: 90%

  .top-slogan
    font-size: 2rem

  .top-sub-slogane
    font-size: small

  .top-img-1
    width: 70%

  .top-img-2
    width: 20%
    top: 1.7rem
    left: .5rem

  .top-img-3
    width: 30%
    left: 1.5rem
    top: 2rem

  .btn-flat-border2
    font-size: large

  .title, .title2
    font-size: 22px

  .text-1, .overview
    font-size: small
    letter-spacing: 2px
    line-height: 2x
    padding: 10px

  .cloud1
    left: 10px
    width: 25%
    bottom: 10%
    animation: img_box_9955 6s linear infinite
    transform-origin: 50% 50%
    margin: 1rem 0 !important

  .cloud2
    transform: scale(-1, 1)
    width: 20%
    right: 8%
    animation: img_box_9955 8s linear infinite
    transform-origin: 50% 50%
    margin: 1rem 0 !important

  .phone-img
    max-width: 40%

  .text-2
    border-bottom: solid 3px #FFDF88
    color: #ffffff
    font-size: medium
    font-weight: bold
    display: inline-block

  .text-3
    color: #ffffff
    font-size: small
    padding: 5px
    font-weight: bold

  .text-4
    color: #ffffff
    font-size: medium
    font-weight: bold
    padding: 10px
    display: inline-block

  .climbing-img
    max-width: 80%

  .tool-img
    max-width: 300px
    margin-top: 20px

  .tool-img-item
    max-width: 50px

  .box
    margin: 0 auto
    height: 200px
    width: 300px

    // padding: 2em 1em;

  .box2
    margin: 0 auto
    height: 250px
    width: 300px

    // padding: 2em 1em;

  .text-5
    font-size: 17px
    padding: .5em 0

  .text-6
    font-size: 13px

  .pay-info
    box-shadow: 10px 10px 0px 0 rgb(184, 148, 50)

    .text1
      color: #2B546A
      font-size: 1.5rem

    .text2
      color: #2B546A
      font-size: 1rem

    .text3
      color: #2B546A
      font-size: .8rem

  .add-button
    background-color: #ffffff
    border-radius: 50px
    font-size: 20px

  .yen
    font-size: 2rem

  .faq-title
    color: #2B546A
    font-size: 1.1rem
    font-weight: bold

  .faq-text
    color: #2B546A
    font-size: .9rem

  .faq-button
    background-color: #FFCB3D
    border-radius: 50px
    font-weight: bold
    font-size: medium

@media (min-width: 1200px)
  .top-logo
    width: 430px

  .top-slogan
    font-size: 4rem
    color: #2B546A
    font-weight: bold
    white-space: nowrap
</style>