var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-manage" }, [
    _vm._m(0),
    _c("div", { staticClass: "paging" }, [
      _vm._m(1),
      _c("div", { staticClass: "d-flex flex-row" }, [
        _c(
          "div",
          {
            staticClass:
              "d-xl-none text-center row no-gutters justify-content-center text-md-left mt-2 w-100"
          },
          [
            _c(
              "b-button",
              {
                staticClass: "col-3 mr-md-4 btn-flat-border",
                attrs: { variant: "outline-info" },
                on: {
                  click: function($event) {
                    return _vm.scrollCard("new-info")
                  }
                }
              },
              [_vm._v("新着情報")]
            ),
            _c(
              "b-button",
              {
                staticClass: "col-4 mr-md-4 btn-flat-border text-nowrap",
                attrs: { variant: "outline-info" },
                on: {
                  click: function($event) {
                    return _vm.scrollCard("overview")
                  }
                }
              },
              [_vm._v("ヤマラボとは")]
            ),
            _c(
              "b-button",
              {
                staticClass: "col-2 mr-md-4 btn-flat-border",
                attrs: { variant: "outline-info" },
                on: {
                  click: function($event) {
                    return _vm.scrollCard("function")
                  }
                }
              },
              [_vm._v("機能")]
            ),
            _c(
              "b-button",
              {
                staticClass: "col-4 btn-flat-border",
                attrs: { variant: "outline-info" },
                on: {
                  click: function($event) {
                    return _vm.scrollCard("plan")
                  }
                }
              },
              [_vm._v("料金プラン")]
            ),
            _c(
              "b-button",
              {
                staticClass: "col-3 btn-flat-border",
                attrs: { variant: "outline-info" },
                on: {
                  click: function($event) {
                    return _vm.scrollCard("faq")
                  }
                }
              },
              [_vm._v("FAQ")]
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "d-none d-xl-block text-center justify-content-center px-md-4 text-md-left mt-2"
          },
          [
            _c(
              "b-button",
              {
                staticClass: "mr-md-4 btn-flat-border-pc text-nowrap",
                attrs: { variant: "outline-info" },
                on: {
                  click: function($event) {
                    return _vm.scrollCard("new-info")
                  }
                }
              },
              [_vm._v("新着情報")]
            ),
            _c(
              "b-button",
              {
                staticClass: "mr-md-4 btn-flat-border-pc text-nowrap",
                attrs: { variant: "outline-info" },
                on: {
                  click: function($event) {
                    return _vm.scrollCard("overview")
                  }
                }
              },
              [_vm._v("ヤマラボとは")]
            ),
            _c(
              "b-button",
              {
                staticClass: "mr-md-4 btn-flat-border-pc text-nowrap",
                attrs: { variant: "outline-info" },
                on: {
                  click: function($event) {
                    return _vm.scrollCard("function")
                  }
                }
              },
              [_vm._v("機能")]
            ),
            _c(
              "b-button",
              {
                staticClass: "mr-md-4 btn-flat-border-pc text-nowrap",
                attrs: { variant: "outline-info" },
                on: {
                  click: function($event) {
                    return _vm.scrollCard("plan")
                  }
                }
              },
              [_vm._v("料金プラン")]
            ),
            _c(
              "b-button",
              {
                staticClass: "mr-md-4 btn-flat-border-pc text-nowrap",
                attrs: { variant: "outline-info" },
                on: {
                  click: function($event) {
                    return _vm.scrollCard("faq")
                  }
                }
              },
              [_vm._v("FAQ")]
            )
          ],
          1
        ),
        _vm._m(2)
      ])
    ]),
    _vm._m(3),
    _vm._m(4),
    _vm._m(5),
    _vm._m(6),
    _vm._m(7),
    _vm._m(8)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-page row no-gutters" }, [
      _c(
        "div",
        {
          staticClass: "col-6 top-left text-left pl-3 pl-lg-5",
          staticStyle: { "z-index": "999" }
        },
        [
          _c("img", {
            staticClass: "top-logo",
            attrs: { src: "/img/description/logo.png", alt: "" }
          }),
          _c("div", { staticClass: "top-slogan" }, [
            _vm._v("スマホ１つで"),
            _c("br"),
            _vm._v(" 岩場の情報がわかる")
          ]),
          _c("div", { staticClass: "top-sub-slogane" }, [
            _vm._v("外岩クライマー専用のWebサービス")
          ])
        ]
      ),
      _c("div", { staticClass: "col-6 position-relative" }, [
        _c("img", {
          staticClass: "top-img-1 float-right",
          attrs: { src: "/img/description/top_img_1.png", alt: "" }
        }),
        _c("img", {
          staticClass: "top-img-2 position-absolute",
          attrs: { src: "/img/description/top_img_2.png", alt: "" }
        }),
        _c("img", {
          staticClass: "top-img-3 position-absolute",
          attrs: { src: "/img/description/top_img_2.png", alt: "" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center d-xl-none mb-3" }, [
      _c(
        "a",
        {
          staticClass: "btn-flat-border2",
          attrs: {
            href: "https://www.yamalab.jp/",
            target: "_blank",
            rel: "noopener"
          }
        },
        [_vm._v("ヤマラボを始める")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "a",
        {
          staticClass:
            "col-6 col-md text-right d-none d-xl-inline btn-flat-border2 text-nowrap",
          attrs: {
            href: "https://www.yamalab.jp/",
            target: "_blank",
            rel: "noopener"
          }
        },
        [_vm._v("ヤマラボを始める")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lp-info mt-md-5 mt-4" }, [
      _c("div", { staticClass: "container p-md-5 pb-5 pt-3 px-3" }, [
        _c("div", { attrs: { id: "new-info" } }),
        _c("div", { staticClass: "title2" }, [_vm._v("新着情報")]),
        _c("div", { staticClass: "row text-center mt-3" }, [
          _c("div", { staticClass: "col-6 col-md-3 mb-3" }, [
            _c(
              "a",
              { staticClass: "text-white", attrs: { href: "/blog/20210330" } },
              [
                _c("img", {
                  staticClass: "blog-thumbnail-img",
                  attrs: { src: "/img/blog/20210329/IMG_3197.jpg", alt: "" }
                }),
                _c(
                  "div",
                  { staticClass: "text-left py-1 px-2 blog-info-title" },
                  [_vm._v("豊田")]
                ),
                _c("div", { staticClass: "text-left px-2" }, [
                  _vm._v("2021/03/30")
                ]),
                _c("div", { staticClass: "text-left px-2 title-text" }, [
                  _vm._v("お久しぶりです。ヤマラボ管理人です。")
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "col-6 col-md-3 mb-3" }, [
            _c(
              "a",
              { staticClass: "text-white", attrs: { href: "/blog/20210115" } },
              [
                _c("img", {
                  staticClass: "blog-thumbnail-img",
                  attrs: { src: "/img/blog/20210115/DSCN2793.JPG", alt: "" }
                }),
                _c(
                  "div",
                  { staticClass: "text-left py-1 px-2 blog-info-title" },
                  [_vm._v("笠置山｜新しいトポ追加のお知らせ")]
                ),
                _c("div", { staticClass: "text-left px-2" }, [
                  _vm._v("2021/01/15")
                ]),
                _c("div", { staticClass: "text-left px-2 title-text" }, [
                  _vm._v(
                    "新年あけましておめでとうございます。ヤマラボ管理人です。"
                  )
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "col-6 col-md-3 mb-3" }, [
            _c(
              "a",
              { staticClass: "text-white", attrs: { href: "/blog/20201203" } },
              [
                _c("img", {
                  staticClass: "blog-thumbnail-img",
                  attrs: { src: "/img/blog/20201203/DSC_0639.JPG", alt: "" }
                }),
                _c(
                  "div",
                  { staticClass: "text-left py-1 px-2 blog-info-title" },
                  [_vm._v("フクベ・新川・大倉・白峰｜新しいトポ追加のお知らせ")]
                ),
                _c("div", { staticClass: "text-left px-2" }, [
                  _vm._v("2020/12/03")
                ]),
                _c("div", { staticClass: "text-left px-2 title-text" }, [
                  _vm._v("こんにちは、ヤマラボ管理人です。")
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "col-6 col-md-3 mb-3" }, [
            _c(
              "a",
              { staticClass: "text-white", attrs: { href: "/blog/20201120" } },
              [
                _c("img", {
                  staticClass: "blog-thumbnail-img",
                  attrs: {
                    src: "/img/blog/20201120/IMG_2436-300x225.jpg",
                    alt: ""
                  }
                }),
                _c(
                  "div",
                  { staticClass: "text-left py-1 px-2 blog-info-title" },
                  [_vm._v("フクベ(瓢) ＆ 笠置山")]
                ),
                _c("div", { staticClass: "text-left px-2" }, [
                  _vm._v("2020/11/20")
                ]),
                _c("div", { staticClass: "text-left px-2 title-text" }, [
                  _vm._v("こんにちは。ヤマラボ管理人です。")
                ])
              ]
            )
          ])
        ]),
        _c("a", { staticClass: "float-right pb-4", attrs: { href: "/blog" } }, [
          _c("div", { staticClass: "d-inline text-white" }, [
            _vm._v("もっとみる")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "background-color": "white" } }, [
      _c("div", { staticClass: "container p-md-3 p-2" }, [
        _c("div", { attrs: { id: "overview" } }),
        _c("div", { staticClass: "title m-2 pt-3" }, [_vm._v("ヤマラボとは?")]),
        _c("div", { staticClass: "d-flex flex-column flex-lg-row" }, [
          _c("div", { staticClass: "text-center overview p-md-4" }, [
            _vm._v(
              "ヤマラボは外岩クライマー専用のWebサービスです。岩のトポが写真で確認できたり、登ったトポを記録したりできます。"
            )
          ]),
          _c(
            "div",
            {
              staticClass:
                "text-center mt-3 position-relative d-block pl-md-5 pb-5"
            },
            [
              _c("img", {
                staticClass: "phone-img",
                attrs: { src: "/img/description/top_img_3.png", alt: "" }
              }),
              _c("img", {
                staticClass: "position-absolute cloud1",
                attrs: { src: "/img/description/top_img_2.png", alt: "" }
              }),
              _c("img", {
                staticClass: "position-absolute cloud2",
                attrs: { src: "/img/description/top_img_2.png", alt: "" }
              })
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lp-info mt-md-5 mt-4" }, [
      _c("div", { staticClass: "container pb-5" }, [
        _c("div", { staticClass: "title m-2 pt-3 text-white" }, [
          _vm._v("岩登りについて")
        ]),
        _c("div", { staticClass: "text-1 px-3 p-md-4 text-white" }, [
          _vm._v(
            "山や川沿いへ行くと大きな岩や岩壁があります。その岩を、自分の手足で登る遊びが岩登りです。登り方によって様々な種類があります。"
          )
        ]),
        _c(
          "div",
          {
            staticClass: "d-flex flex-column flex-md-row justify-content-center"
          },
          [
            _c("div", { staticClass: "text-center" }, [
              _c("div", [
                _c("div", { staticClass: "text-2" }, [_vm._v("ボルダリング")])
              ]),
              _c("img", {
                staticClass: "climbing-img mt-3 px-3",
                attrs: { src: "/img/description/bouldering_img.jpg", alt: "" }
              }),
              _c("div", { staticClass: "text-3" }, [
                _vm._v("ボルダリングで登られる岩の形")
              ]),
              _c("div", { staticClass: "text-4" }, [
                _vm._v("4m程度までの大きな岩を登ります。")
              ])
            ]),
            _c("div", { staticClass: "text-center" }, [
              _c("div", [
                _c("div", { staticClass: "text-2" }, [
                  _vm._v("ルートクライミング")
                ])
              ]),
              _c("img", {
                staticClass: "climbing-img mt-3 px-3",
                attrs: { src: "/img/description/climbing_img.JPG", alt: "" }
              }),
              _c("div", { staticClass: "text-3" }, [
                _vm._v("ルートクライミングで登られる岩の形")
              ]),
              _c("div", { staticClass: "text-4" }, [
                _vm._v("4m程度以上の岩壁をロープ使って"),
                _c("br"),
                _vm._v(" (安全確保のため)登ります。")
              ])
            ])
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "background-color": "white" } }, [
      _c("div", { staticClass: "container p-md-3 p-2" }, [
        _c("div", { attrs: { id: "function" } }),
        _c("div", { staticClass: "title m-2 pt-3" }, [
          _vm._v("ヤマラボの機能紹介")
        ]),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column-reverse flex-lg-row justify-content-center mt-3"
          },
          [
            _c("div", { staticClass: "text-center mt-md-3 mx-md-3" }, [
              _c("img", {
                staticClass: "tool-img",
                attrs: { src: "/img/description/tool_img_1-1.jpg", alt: "" }
              })
            ]),
            _c("div", { staticClass: "text-center mt-md-3 mx-lg-2 box2" }, [
              _c("div", [
                _c("img", {
                  staticClass: "tool-img-item mt-5 mt-lg-5",
                  attrs: { src: "/img/description/tool_img_1-2.png", alt: "" }
                }),
                _c("div", { staticClass: "text-5 mt-3 mt-lg-4 p-2" }, [
                  _vm._v("国内の約200岩場を掲載!")
                ]),
                _c("div", { staticClass: "text-6 p-2" }, [
                  _vm._v("全国の岩場のルートを掲載"),
                  _c("br"),
                  _vm._v(" キーワード検索から岩場検索"),
                  _c("br"),
                  _vm._v(" できます。")
                ])
              ])
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-lg-row justify-content-center mt-5"
          },
          [
            _c("div", { staticClass: "text-center mt-md-3 mx-lg-2 box2" }, [
              _c("div", [
                _c("img", {
                  staticClass: "tool-img-item mt-4 mt-lg-5",
                  attrs: { src: "/img/description/tool_img_2-2.png", alt: "" }
                }),
                _c("div", { staticClass: "text-5 mt-3 mt-lg-4 p-2" }, [
                  _vm._v("関東エリアの画像トポ公開!")
                ]),
                _c("div", { staticClass: "text-6 p-2" }, [
                  _vm._v("ルートがわからない時写真でトポの確認"),
                  _c("br"),
                  _vm._v(" ができます。"),
                  _c("br"),
                  _vm._v(" ※掲載エリアは順次拡大予定")
                ])
              ])
            ]),
            _c("div", { staticClass: "text-center mt-md-3 mx-md-3" }, [
              _c("img", {
                staticClass: "tool-img",
                attrs: { src: "/img/description/tool_img_2-1.png", alt: "" }
              })
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column-reverse flex-lg-row justify-content-center mt-5"
          },
          [
            _c("div", { staticClass: "text-center mt-md-3 mx-md-3" }, [
              _c("img", {
                staticClass: "tool-img",
                attrs: { src: "/img/description/tool_img_3-1.png", alt: "" }
              })
            ]),
            _c("div", { staticClass: "text-center mt-md-3 mx-lg-2 box2" }, [
              _c("div", [
                _c("img", {
                  staticClass: "tool-img-item mt-4 mt-lg-5",
                  attrs: { src: "/img/description/tool_img_3-2.png", alt: "" }
                }),
                _c("div", { staticClass: "text-5 mt-3 mt-lg-4 p-2" }, [
                  _vm._v("近くのコンビニ・温泉表示")
                ]),
                _c("div", { staticClass: "text-6 p-2" }, [
                  _vm._v("外岩クライミングに便利な情報"),
                  _c("br"),
                  _vm._v(" そこまでの距離も表示することが"),
                  _c("br"),
                  _vm._v(" できます。")
                ])
              ])
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-lg-row justify-content-center mt-5"
          },
          [
            _c("div", { staticClass: "text-center mt-md-3 mx-lg-2 box2" }, [
              _c("div", [
                _c("img", {
                  staticClass: "tool-img-item mt-4 mt-lg-5",
                  attrs: { src: "/img/description/tool_img_4-2.png", alt: "" }
                }),
                _c("div", { staticClass: "text-5 mt-3 mt-lg-4 p-2" }, [
                  _vm._v("クライミング活動の記録")
                ]),
                _c("div", { staticClass: "text-6 p-2" }, [
                  _vm._v("ルート単位の登攀記録作成が可能"),
                  _c("br"),
                  _vm._v(" いつでも作成した記録を見返すこと"),
                  _c("br"),
                  _vm._v(" もできます。")
                ])
              ])
            ]),
            _c("div", { staticClass: "text-center mt-md-3 mx-md-3" }, [
              _c("img", {
                staticClass: "tool-img",
                attrs: { src: "/img/description/tool_img_4-1.jpg", alt: "" }
              })
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column-reverse flex-lg-row justify-content-center mt-5"
          },
          [
            _c("div", { staticClass: "text-center mt-md-3 mx-md-3" }, [
              _c("img", {
                staticClass: "tool-img",
                attrs: { src: "/img/description/tool_img_5-1.png", alt: "" }
              })
            ]),
            _c("div", { staticClass: "text-center mt-md-3 mx-lg-2 box2" }, [
              _c("div", [
                _c("img", {
                  staticClass: "tool-img-item mt-4 mt-lg-5",
                  attrs: { src: "/img/description/tool_img_5-2.png", alt: "" }
                }),
                _c("div", { staticClass: "text-5 mt-3 mt-lg-4 p-2" }, [
                  _vm._v("岩場のチェックイン数の表示")
                ]),
                _c("div", { staticClass: "text-6 pt-lg-4 p-2" }, [
                  _vm._v("他ユーザーのチェックイン状況が"),
                  _c("br"),
                  _vm._v(" 確認できる！")
                ])
              ])
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column flex-lg-row justify-content-center my-5"
          },
          [
            _c("div", { staticClass: "text-center mt-md-3 mx-lg-2 box2" }, [
              _c("div", [
                _c("img", {
                  staticClass: "tool-img-item mt-4 mt-lg-5",
                  attrs: { src: "/img/description/tool_img_6-2.png", alt: "" }
                }),
                _c("div", { staticClass: "text-5 mt-3 mt-lg-4 p-2" }, [
                  _vm._v("キャラクターバッジの取得")
                ]),
                _c("div", { staticClass: "text-6 p-2" }, [
                  _vm._v("記録やチェックインが増えると"),
                  _c("br"),
                  _vm._v(" キャラが成長。"),
                  _c("br"),
                  _vm._v(" ゲーム感覚で楽しむことができます。")
                ])
              ])
            ]),
            _c("div", { staticClass: "text-center mt-md-3 mx-md-3" }, [
              _c("img", {
                staticClass: "tool-img",
                attrs: { src: "/img/description/tool_img_6-1.jpg", alt: "" }
              })
            ])
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lp-info mt-md-5 mt-4" }, [
      _c("div", { staticClass: "container p-md-5 pb-5 pt-3 px-3" }, [
        _c("div", { attrs: { id: "plan" } }),
        _c("div", { staticClass: "title m-2 pt-3 text-white" }, [
          _vm._v("ヤマラボの料金プラン")
        ]),
        _c("div", { staticClass: "text-1 px-3 px-md-4 text-white" }, [
          _vm._v("有料プランですべての機能が使えるようになります。")
        ]),
        _c("div", { staticClass: "mt-4 text-center text-white" }, [
          _c("div", { staticClass: "pay-info d-inline-block p-4 px-5" }, [
            _c("div", { staticClass: "text1" }, [_vm._v("有料プラン")]),
            _c("div", { staticClass: "text2" }, [
              _vm._v("月額 "),
              _c("span", { staticClass: "yen" }, [_vm._v("300")]),
              _vm._v(" 円(税込み)")
            ]),
            _c("div", { staticClass: "text-left d-inline-block" }, [
              _c("div", { staticClass: "text3" }, [_vm._v("・岩場の検索")]),
              _c("div", { staticClass: "text3" }, [_vm._v("・画像トポの参照")]),
              _c("div", { staticClass: "text3" }, [_vm._v("・登攀記録の作成")]),
              _c("div", { staticClass: "text3" }, [
                _vm._v("・岩場のチェックイン")
              ])
            ])
          ]),
          _c("div", { staticClass: "title mt-4 mt-md-5 text-white" }, [
            _vm._v("初月は利用無料!!")
          ]),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.yamalab.jp/register_user/send_auth",
                target: "_blank",
                rel: "noopener"
              }
            },
            [
              _c(
                "div",
                { staticClass: "add-button d-inline-block m-4 p-2 px-5" },
                [_vm._v("新規会員登録はこちら")]
              )
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "background-color": "white" } }, [
      _c("div", { staticClass: "container p-3" }, [
        _c("div", { attrs: { id: "faq" } }),
        _c(
          "div",
          { staticClass: "title2", staticStyle: { color: "#2B546A" } },
          [_vm._v("FAQ")]
        ),
        _c("div", { staticClass: "faq-title py-2" }, [
          _vm._v("Q. ヤマラボはアプリですか？")
        ]),
        _c("div", { staticClass: "faq-text" }, [
          _vm._v("A. ヤマラボはスマートフォンアプリではございません。"),
          _c("br"),
          _vm._v(
            " PC、タブレット、スマートフォン端末のWEBブラウザからご利用いただけます。"
          )
        ]),
        _c("hr", {
          staticStyle: { border: "0", "border-top": "1px solid #707070" }
        }),
        _c("div", { staticClass: "faq-title py-2" }, [
          _vm._v("Q. 無料で使えますか？")
        ]),
        _c("div", { staticClass: "faq-text" }, [
          _vm._v(
            "A. ユーザー登録なしで全国の約200岩場を検索できます。有料プランにご契約頂きますと「岩場のルート一覧」や「画像トポ」の参照、「記録の作成」が可能になります。詳しくはこちらもご覧ください。"
          )
        ]),
        _c("div", { staticClass: "text-center my-3" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.yamalab.jp/info/faq",
                target: "_blank",
                rel: "noopener"
              }
            },
            [
              _c(
                "div",
                { staticClass: "faq-button d-inline-block m-4 p-2 px-5" },
                [_vm._v("その他よくあるご質問はこちら")]
              )
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }